.diagram-component {
  width: 100%;
  height: calc(100vh - 175px);
  border: solid 1px black;
  background-color: #fff;
}

.diagram-component_dark {
  background-color: #303030;
}
